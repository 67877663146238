// see documentation: https://swiperjs.com/get-started
import Swiper, {
  Autoplay, Navigation, Pagination,
} from 'swiper';

// data attributes
const SWIPER_DATA_ATTRIBUTE = 'data-select-swiper';
const SWIPER_DATA_ATTRIBUTE_ID = 'data-param-swiper-id';
const SWIPER_DATA_ATTRIBUTE_LAYOUT = 'data-param-swiper-layout';

const BASE_CONFIG = {
  direction: 'horizontal',
  speed: 400,
  loop: true,
  autoplay: {
    delay: 5000,
  },
  spaceBetween: 15,
  lazy: {
    loadPrevNext: true,
  },
};

const LAYOUT_1 = {
  slidesPerView: 1,
};

const LAYOUT_1_2 = {
  slidesPerView: 1,
  breakpoints: {
    768: {
      slidesPerView: 2,
    },
  },
};

const LAYOUT_1_2_3 = {
  slidesPerView: 1,
  breakpoints: {
    768: {
      slidesPerView: 2,
    },
    992: {
      slidesPerView: 3,
    },
  },
};

Swiper.use([Autoplay, Navigation, Pagination]);

document.addEventListener('DOMContentLoaded', () => {
  const swiperContainers = document.querySelectorAll(`[${SWIPER_DATA_ATTRIBUTE}]`);
  const totalSwiperContainers = swiperContainers.length;
  swiperContainers.forEach(swiper => {
    // swiper id
    const swiperId = swiper.getAttribute(SWIPER_DATA_ATTRIBUTE_ID) || '';

    // check if id is set
    if (1 < totalSwiperContainers && '' === swiperId) {
      throw new Error('swiper id is missing. Set id when more than one swiper is used');
    }

    // define swiper data attribute name
    const swiperAttributeId = '' === swiperId ? '' : `[${SWIPER_DATA_ATTRIBUTE_ID}="${swiperId}"]`;

    // swiper layout configuration
    const swiperLayoutConfig = () => {
      switch (swiper.getAttribute(SWIPER_DATA_ATTRIBUTE_LAYOUT)) {
        case '1-2':
          return LAYOUT_1_2;
        case '1-2-3':
          return LAYOUT_1_2_3;
        default:
          return LAYOUT_1;
      }
    };

    // eslint-disable-next-line no-new
    new Swiper(`[${SWIPER_DATA_ATTRIBUTE}]${swiperAttributeId} .swiper`, {
      ...BASE_CONFIG,
      ...swiperLayoutConfig(),
      pagination: {
        el: `.swiper-pagination${swiperId}`,
        clickable: true,
      },
      navigation: {
        nextEl: `.swiper-button-next${swiperId}`,
        prevEl: `.swiper-button-prev${swiperId}`,
      },
    });
  });
});
